import { useContext } from "react";

// Context
import { PageContext } from "providers/PageProvider";
const jsonPath = require("jsonpath");

const usePageData = (path?: string | undefined) => {
  const data = useContext(PageContext);

  if (path) {
    const queryResult = jsonPath.query(data, path);
    if (queryResult) {
      return queryResult;
    }
  }
  return data;
};

export default usePageData;
