import { useRouter } from "next/router";

import { Box, BoxProps } from "@chakra-ui/react";

import { getQueryString } from "@utils/common";
import BaseLink from "./BaseLink";

interface Props extends BoxProps {
  link: string;
  linkAs?: string;
}

const LinkComponent = ({ link, children, ...rest }: Props) => {
  const router = useRouter();

  const { query } = router;

  const queryString = getQueryString(query);

  return (
    <BaseLink href={`${link}${queryString ? `?${queryString}` : ""}`}>
      <Box {...rest}>{children}</Box>
    </BaseLink>
  );
};

export default LinkComponent;
