import { STRAPI_URL } from "@config/network";
import {
  Polygon,
  Fantom,
  Hedera,
  Binance,
  Terra_2_0,
  Near,
  Twitter,
  Telegram,
  Discord,
  SDStake,
  EmailIcon,
  Reddit,
  LitePaper,
  TwitterSolid,
  EthNoGB,
} from "icons";

export const strapiRequestHeader = {
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_BEARER_TOKEN}`,
  },
};

export async function fetchPageData(
  pageAPI: string,
  pageId: number | undefined = undefined,
  params?: any
) {
  let query = `${STRAPI_URL}/api/${pageAPI}`;
  if (pageId) {
    query += `/${pageId}`;
  }
  query += "?";
  if (params) {
    query += params;
  }
  const response = await fetch(`${query}&populate=deep`, {
    ...strapiRequestHeader,
  });

  return await response.json();
}

export const getBlockChainIcon = (blockchain: string) => {
  switch (blockchain) {
    case "hedera":
      return Hedera;
    case "polygon":
      return Polygon;
    case "bnb":
      return Binance;
    case "near":
      return Near;
    case "fantom":
      return Fantom;
    case "terra":
      return Terra_2_0;
    case "sd":
      return SDStake;
    case "eth":
      return EthNoGB;
  }
};

export const getCommunityIcon = (community: string) => {
  switch (community) {
    case "twitter":
      return Twitter;
    case "telegram":
      return Telegram;
    case "discord":
      return Discord;
    case "email":
      return EmailIcon;
    case "reddit":
      return Reddit;
    case "litepaper":
      return LitePaper;
    case "twitterSolid":
      return TwitterSolid;
  }
};

export const RIGHT_SECTION_KEYS = {
  UTILITIES: "utilities",
  CONTRACTS: "contracts",
  AUDITS: "audits",
  SUBSCRIPTION: "subscription",
  PLATFORM: "platform",
  DASHBOARD: "dashboard",
  ETHXSTATISTICS: "ethx_statistics",
  SDSTATISTICS: "sd_statistics",
};

export const convertNumberToStringRepresentation = (num: string) => {
  const updatedNumber = Number(num);
  if (updatedNumber > 999 && updatedNumber < 1000000) {
    return (updatedNumber / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (updatedNumber > 1000000) {
    return (updatedNumber / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (updatedNumber < 900) {
    return num; // if value < 1000, nothing to do
  }
};

const getValue = (
  isFloor: boolean,
  isSlice: boolean,
  value: number,
  maxFractionDigits: number
) => {
  if (isFloor) {
    return Math.floor(value);
  } else if (isSlice) {
    const updatedValue = value.toString();

    if (updatedValue.indexOf(".") !== -1) {
      return Number(
        updatedValue.slice(0, updatedValue.indexOf(".") + maxFractionDigits + 1)
      );
    }
    return value;
  }
  return value;
};

export const formatNumber = (
  value: number,
  isFloor: boolean = true,
  maxFractionDigits = 4,
  isSlice: boolean = false
) => {
  const updatedValue = getValue(isFloor, isSlice, value, maxFractionDigits);
  return updatedValue.toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits,
  });
};
