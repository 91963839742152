import { useRouter } from "next/router";
import { useMemo } from "react";
import {
  Box,
  HStack,
  Flex,
  Text,
  Center,
  Button,
  Show,
} from "@chakra-ui/react";

import LinkComponent from "@components/Link";
import { trackEvent } from "dapp-sdk-v2";

import { STAKE_NOW_CTA } from "@constants/analytics";
import BaseImage from "dapp-sdk-v2/components/Image";

interface HeaderProps {
  headerBG?: string;
  isPrimaryCTArequired?: boolean;
}

const TOKEN = process.env.NEXT_PUBLIC_TOKEN;

const Header = ({ headerBG, isPrimaryCTArequired = true }: HeaderProps) => {
  const router = useRouter();

  const subMenu = useMemo(() => {
    return (
      <Flex>
        <LinkComponent link="/">
          <Text
            p="10px 12px"
            fontWeight="600"
            fontSize={{ base: "14px", lg: "16px" }}
            color={router.pathname === "/" ? "#07A658" : "#171923"}
            cursor="pointer"
          >
            ETHx
          </Text>
        </LinkComponent>
        <LinkComponent link="/node-operators">
          <Text
            p="10px 12px"
            fontWeight="600"
            fontSize={{ base: "14px", lg: "16px" }}
            color={
              router.pathname.includes("node-operators") ? "#07A658" : "#171923"
            }
            cursor="pointer"
            w="max-content"
          >
            Node operator
          </Text>
        </LinkComponent>
      </Flex>
    );
  }, []);

  return (
    <Box
      w={{ base: "100vw", lg: "full" }}
      zIndex="1401"
      sx={{
        position: "fixed",
        top: "0",
      }}
      bg={headerBG ? headerBG : "white"}
    >
      <Center w={{ base: "100vw", lg: "full" }}>
        <Box maxW="1192px" p={{ base: 3, lg: "24px 0 20px" }} w="100%">
          <HStack justify="space-between" w="100%">
            <Flex w="100%" gap="64px">
              <Flex as="a" href="/" align="center">
                <Center ml={-2}>
                  <Show above="lg">
                    <BaseImage
                      src={"/assets/images/stader-icon.svg"}
                      alt="stader-icon"
                      width={40}
                      height={40}
                    />
                  </Show>
                  <Show below="lg">
                    <BaseImage
                      src={"/assets/images/stader-icon.svg"}
                      alt="stader-icon"
                      width={32}
                      height={32}
                    />
                  </Show>
                </Center>
                <Text fontSize={{ base: 22, lg: 28 }} fontWeight={600}>
                  Stader
                </Text>
              </Flex>

              {TOKEN === "ETH" ? <Show above="lg">{subMenu}</Show> : null}
            </Flex>
            <Flex>
              {isPrimaryCTArequired && (
                <Show above="lg">
                  <LinkComponent
                    link="/stake"
                    onClick={() => trackEvent(STAKE_NOW_CTA)}
                  >
                    <Button
                      p="8px 16px"
                      bg="primary"
                      h="40px"
                      color="white"
                      w="max-content"
                    >
                      Stake now
                      <Box ml="12px" h="24px" w="24px">
                        <BaseImage
                          src="/assets/images/right-arrow.svg"
                          alt="arrow"
                          width={24}
                          height={24}
                        />
                      </Box>
                    </Button>
                  </LinkComponent>
                </Show>
              )}

              {TOKEN === "ETH" ? <Show below="lg">{subMenu}</Show> : null}
            </Flex>
          </HStack>
        </Box>
      </Center>
    </Box>
  );
};

export default Header;
