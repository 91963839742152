import { Box } from "@chakra-ui/react";

interface IconType {
  width: string;
  height: string;
  color?: string;
  Icon: any;
  hoverColor?: string;
}

const Icon = ({
  width,
  height,
  color = "inherit",
  Icon,
  hoverColor,
}: IconType) => {
  return (
    <Box
      w={width}
      h={height}
      color={color}
      _hover={{
        color: hoverColor ? hoverColor : color,
      }}
    >
      <Icon width="100%" height="100%" color="inherit" />
    </Box>
  );
};

export default Icon;
