import { IIndexable } from "@customtypes/common";

export const JOIN_OUR_COMMUNITY = "Join_our_community";
export const STADER_LOGO = "Stader_logo";
export const EXPLORE_STAKING = "Explore_staking";
export const GOVERNANCE_OPTION_CLICK = "Governance_option_click";
export const STADER_VERSION = "Stader_version";
export const VERSION = 3;
export const SUPPORT = "Support";
export const TERMS_OF_SERVICE = "Terms_of_service";
export const THEME_CHANGE = "Theme_change";

export const AUDIT_TYPE = "Audit_type";
export const AUDITS_VIEW_MORE = "Audit_view_more";
export const NEWSLETTER = "Newsletter";
export const CONTRACT_ADDRESS_CLICK = "Contract_address_click";
export const ANALYTICS_DASBOARD_CLICK = "Analytics_dashboard_click";

export const NODE_SPIN_CLICK = "Node_spin_click";

export const CLICK_DEFI_PARTNER_LINK = "Defi";
export const DEFI_CAROUSEL_CTA = "Defi_Carousel_CTA";

// HOME PAGE
export const PRIMARY_TABS = "primary_tabs";
export const STAKE_NOW_CTA = "stake_now_CTA";
export const READ_MORE_FAQ = "read_more_faq";
export const AUDIT_CLICK = "audit_click";
export const SD_UTILITY_POOL_CLICK = "sd_utility_pool_click";
export const SOCIALS_CLICK = "socials_click";
export const WATCH_HOW_IT_WORKS = "watch_how_it_works";
export const VIEW_OUR_PARTNERS = "view_our_partners";
export const STAKE_REWARD_CALCULATOR = "stake_reward_calculator";
export const SUBSCRIBE_EMAIL = "subscribe_email";
export const SUBSCRIBE_PRIVACY = "subscribe_privacy";
export const BLOGS_VIEW_MORE = "blogs_view_more";
export const ON_BLOG_CLICK = "on_blog_click";
export const ETH_NODE_OPERATORS_PAGE_CLICK = "eth_node_operators_page_click";
export const MARKETING_PRIMARY_CLICK = "marketing_primary_click";
export const MARKETING_SECONDARY_CLICK = "marketing_secondary_click";
export const FAQ_VIEW_MORE = "faq_view_more";

// NODE PAGE
export const ETH_NODE_OPERATORS_PAGE_LOAD = "ETH_node_operators_page_load";
export const VIEW_MORE_GUIDE = "view_more_guide";
export const LP_ETH_DISCORD_CLICK = "LP_Eth_discord_click";

export const CALC_ETH_INPUT_CHANGE = "calc_eth_input_change";
export const CALC_SD_BOND_CHANGE = "calc_sd_bond_change";

// Filters
export const ALL_FILTER = "all_filter";
export const TAG_FILTER = "tag_filter";
export const CATEGORY_FILTER = "category_filter";

// Landing Footer
export const LP_SOCIAL = "LP_Social";
export const LP_TOS_CLICK = "LP_TOS_click";
export const LP_NETWORKS = "LP_Networks";
export const LP_GOVERNANCE = "LP_Governance";
export const LP_ANALYTICS = "LP_Analytics";
export const LP_DEVELOPERS = "LP_Developers";
export const LP_ABOUT = "LP_About";

// Restake CTA
export const RESTAKE_CTA = "restake_cta";

export const EVENT_MAPPINGS: IIndexable = {
  networks: LP_NETWORKS,
  governance: LP_GOVERNANCE,
  analytics: LP_ANALYTICS,
  developers: LP_DEVELOPERS,
  about: LP_ABOUT,
};
